import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import Layout from "../components/Layout/Layout";
//@ts-ignore
import Checkbox from "../images/checkbox.svg";
import parse from "html-react-parser";
//@ts-ignore
import Certificate from "../images/certificat.svg";
//@ts-ignore
import DownloadPdf from "../images/download_blue.svg";
// @ts-ignore
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import "./about-us.css";
import "./markdown.css";
import BreadCrumbs from "../BreadCrumbs";
import License from "../images/license.pdf";
import ReactMarkdown from "react-markdown";
import { useQuery } from "@apollo/client";
import { GET_INFOS_BY_LABEL } from "../query/getInfos";
// @ts-ignore
const aboutUs = ({ intl, pageContext }) => {
  const { loading, error, data } = useQuery(GET_INFOS_BY_LABEL, {
    variables: {
      label: "about-us-info",
      locale: intl.locale,
    },
  });

  if (loading) return intl.formatMessage({ id: "Loading.LoadingTitle" });

  if (error) return intl.formatMessage({ id: "Error.ErrorTitle" });

  return (
    <Layout>
      <div className="flex items-center justify-center bg-[#FAFAFA] ">
        <div className="mb-[10rem] flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
          <div className=" mt-[2.5rem] mb-[2rem] ">
            <BreadCrumbs
              pageContext={pageContext}
              title="AboutUs.AboutUsHeader"
            />
          </div>
          <h2 className=" text-[2rem] font-semibold text-[#001439]">
            {intl.formatMessage({ id: "AboutUs.AboutUsHeader" })}
          </h2>

          <div className="aboutUsInfo_banner flex  sm:justify-end   mt-[2rem] w-auto bg-[#EDF2FC] sm:h-[12.5rem] xs:h-[15rem] rounded-[1rem]"></div>
          <div className="sm:flex sm:grid-flow-col  xs:grid xs:grid-cols-2  justify-start mt-[2rem] lg:gap-4 sm:gap-[4rem] xs:gap-4">
            <div className="flex justify-center items-center bg-white  sm:rounded-[1.25rem] xs:rounded-[0.5rem] sm:w-[10.25rem] sm:h-[6.26rem] xs:w-[7.25rem] xs:h-[3rem] ">
              <img
                src={Checkbox}
                alt=""
                className="sm:w-[1.8rem] sm:h-[1.8rem] xs:w-[1rem] xs:h-[1rem] mr-[0.6rem] -mt-[1rem]"
              />
              <div className="flex flex-col">
                <p className="font-medium sm:text-[1.5rem] xs:text-[1rem]">
                  {parse(
                    intl.formatMessage({
                      id: "AboutUs.Statistics.FoundingYear",
                    })
                  )}
                </p>
                <p className="font-medium sm:text-[1rem] xs:text-[0.75rem] leading-5">
                  {parse(
                    intl.formatMessage({ id: "AboutUs.Statistics.Founding" })
                  )}
                </p>
              </div>
            </div>
            <div className="flex justify-center items-center bg-white  sm:rounded-[1.25rem] xs:rounded-[0.5rem] lg:ml-[0rem] lg:w-[14rem] sm:-ml-[2rem] sm:w-[13rem] sm:h-[6.26rem] xs:w-[9rem] xs:h-[3rem]">
              <img
                src={Checkbox}
                alt=""
                className="sm:w-[1.8rem] sm:h-[1.8rem] xs:w-[1rem] xs:h-[1rem] mr-[0.6rem] -mt-[1rem]"
              />
              <div className="flex flex-col">
                <p className="font-medium sm:text-[1.5rem] xs:text-[1rem]">
                  {parse(
                    intl.formatMessage({ id: "AboutUs.Statistics.MoreThan300" })
                  )}
                </p>
                <p className="font-medium sm:text-[1rem] xs:text-[0.75rem] leading-5">
                  {parse(
                    intl.formatMessage({ id: "AboutUs.Statistics.Analysis" })
                  )}
                </p>
              </div>
            </div>
            <div className="flex justify-center items-center bg-white  sm:rounded-[1.25rem] xs:rounded-[0.5rem] sm:w-[11.5rem] sm:h-[6.26rem] xs:w-[8rem] xs:h-[3rem]">
              <img
                src={Checkbox}
                alt=""
                className="sm:w-[1.8rem] sm:h-[1.8rem] xs:w-[1rem] xs:h-[1rem] mr-[0.6rem] -mt-[1rem]"
              />
              <div className="flex flex-col">
                <p className="font-medium sm:text-[1.5rem] xs:text-[1rem]">1</p>
                <p className="font-medium sm:text-[1rem] xs:text-[0.75rem] leading-5">
                  {parse(
                    intl.formatMessage({
                      id: "AboutUs.Statistics.Laboratory",
                    })
                  )}
                </p>
              </div>
            </div>
          </div>
          {data && data.infos && data.infos.data.length ? (
            <div className="mt-[2.5rem] flex flex-col markdown">
              <ReactMarkdown>
                {data.infos.data[0].attributes.info}
              </ReactMarkdown>
            </div>
          ) : (
            <>
              <div className="mt-[2.5rem] flex flex-col">
                <h4 className="font-semibold text-[1.25rem]">
                  {intl.formatMessage({ id: "AboutUs.AboutUsInfo.History" })}
                </h4>
                <p className="mt-[1rem] font-medium text-[1rem]">
                  {intl.formatMessage({
                    id: "AboutUs.AboutUsInfo.HistoryDescription",
                  })}
                </p>
              </div>
              <div className="mt-[2.5rem] flex flex-col">
                <ReactMarkdown>
                  {intl.formatMessage({
                    id: "AboutUsPage",
                  })}
                </ReactMarkdown>
              </div>

              {/*<div className="mt-[2.5rem] flex flex-col">*/}
              {/*  <h4 className="font-semibold text-[1.25rem]">*/}
              {/*    {intl.formatMessage({ id: "AboutUs.AboutUsInfo.Mission" })}*/}
              {/*  </h4>*/}
              {/*  <p className="mt-[1rem] font-medium text-[1rem]">*/}
              {/*    {intl.formatMessage({*/}
              {/*      id: "AboutUs.AboutUsInfo.MissionDescription",*/}
              {/*    })}*/}
              {/*  </p>*/}
              {/*</div>*/}
            </>
          )}
          <div className="mt-[2.5rem] flex flex-col">
            <h4 className="font-semibold text-[1.25rem]">
              {intl.formatMessage({
                id: "AboutUs.AboutUsInfo.LicensesAndCertificates",
              })}
            </h4>
            <div className="flex sm:flex-row xs:flex-col gap-4">
              <div className="flex flex-col  mt-[2rem]">
                <img
                  src={Certificate}
                  alt=""
                  className="w-[12.5rem] h-[7.5rem]"
                />
                <a href={License} download className="">
                  <div className="flex mt-[1.25rem]">
                    <img src={DownloadPdf} alt="" />
                    <p className="ml-[6px] font-medium text-[1rem] text-[#618EE4]">
                      {intl.formatMessage({
                        id: "AboutUs.AboutUsInfo.DownloadPdf",
                      })}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(aboutUs);
